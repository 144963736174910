/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-col w-full">
        <vx-card class="mb-base">
          <h3 class="font-bold mb-4">Controls</h3>
          <vue-draggable-container id="wordBank" class="wordBank grid grid-cols-5">
            <h5 class="dragBlock font-semibold cursor-pointer h-fit" v-for="block in dragBlocks">{{ block }}</h5>
          </vue-draggable-container>
        </vx-card>
      </div>

      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-6/12 md:w-6/12 sm:w-5/5">
          <div style="height: 35rem">
            <vue-command
              id="command_prompt"
              :commands="commands"
              :prompt="'C:\\Users\\' + activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '>'"
              :show-intro="true"
              class=""
              intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
              style="background-color: #111111; border: 1px solid #181d2a; border-radius: 0.5rem"
              title="Command Prompt"
            />
          </div>
        </div>
        <div class="vx-col lg:w-6/12 md:w-6/12 sm:w-5/5">
          <div class="grid grid-cols-2 gap-5">
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Broadcast Address</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input0" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Default Gateway</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input1" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Host IP Address</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input2" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Mac Address</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input3" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Network Address</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input4" />
            </div>
            <div class="flex flex-col justify-between rounded-lg p-4 bg-dark main-border">
              <h3 class="font-bold">Last Assignable IP in the Subnet</h3>
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg mt-4 w-full" id="input5" />
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      dragBlocks: [
        '192.168.1.1',
        '192.168.1.20',
        '192.168.1.254',
        '192.168.1.255',
        'B8-76-3F-7C-57-DF',
        '1A-76-3F-7C-57-DF',
        '192.168.1.0',
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      commands: {
        help: () => createStdout('Available commands:<br>&nbsp;ipconfig/all<br>'),
        'ipconfig/all'() {
          return createStdout(`<br><br>-----------------------------------------------<br><br>Windows IP Configuration<br>
                Host Name . . . . . . . . . . . : Inspiron15<br>
                Primary Dns Suffix  . . . . . . : <br>
                Node Type . . . . . . . . . . . : Mixed<br>
                IP Routing Enabled  . . . . . . : No<br>
                WINS Proxy Enabled  . . . . . . : No<br><br>
                Wireless LAN adapter Local Area Connection* 12:<br>
                Media State . . . . . . . . . . : Media disconnected<br>
                Connection-specific DNS Suffix  : <br>
                Description . . . . . . . . . . : Microsoft Wi-Fi Direct Virtual Adapter<br>
                Physical Address  . . . . . . . : 1A-76-3F-7C-57-DF<br>
                DHCP Enabled  . . . . . . . . . : Yes<br>
                Autoconfiguration Enabled . . . : Yes<br><br>
                Wireless LAN adapter Wi-Fi: <br>
                Connection-specific DNS Suffix : <br>
                Description . . . . . . . . . . : Dell Wireless 1703 802.11b/g/n (2.4Ghz)<br>
                Physical Address  . . . . . . . : B8-76-3F-7C-57-DF<br>
                DHCP Enabled . . . . . . . . . : No<br>
                Autoconfiguration Enabled . . . : Yes<br>
                Link-local IPv6 Address . . . . : fe80::e09f:9839:6e86:f755x12 (Preferred)<br>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; . . . . : 192.168.1.20 (Preferred)<br>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; . . . . : 255.255.255.0<br>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; . . . . : 192.168.1.1<br>
                DHCPv6 IAID . . . . . . . . . . : 263747135<br>
                DHCPv6 Client DUID  . . . . . . : 00-01-00-01-18-E6-32-43-B8-76-3F-7C-57-DF<br>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; . . . . : 192.168.1.15<br>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; . . . . : 192.168.1.16<br>
                NetBIOS over Tcpip  . . . . . . : Enabled<br><br>
                -----------------------------------------------<br><br><br>`);
        },
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wb0' && overContainer.id !== 'wb1' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          // this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let total_score = 0;

      if (document.getElementById('input0').textContent === '192.168.1.255') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === '192.168.1.1') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === '192.168.1.20') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'B8-76-3F-7C-57-DF') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === '192.168.1.0') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input5').textContent === '192.168.1.254') {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        total_score++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (total_score === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', total_score);
      }

      this.$emit('simulation_getting_there', total_score);
    },
  },
  components: {
    draggable,
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
#wordBank {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
  min-height: unset !important;
}
</style>
